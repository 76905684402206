


























import { Component, Vue } from 'vue-property-decorator';
import SiteLayout from '@/shared/components/site/SiteLayout.vue';

@Component({
  components: {
    SiteLayout,
  },
})
export default class NotFound extends Vue {
  $refs!: {};
}
